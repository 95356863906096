import React, { useEffect } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import * as actions from 'actions'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

const PREFIX = 'callback'

const classes = {
	container: `${PREFIX}-container`,
}

const Root = styled('div')(({ theme }) => ({
	[`&.${classes.container}`]: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		height: '100vh',
		justifyContent: 'center',
		overflow: 'none',
	},
}))

const Callback = (props) => {
	const { t, location } = props
	const hash = location.hash.replace('#', '')
	const parsedHash = hash.split('&').reduce((acc, item) => {
		const parts = item.split('=')
		acc[parts[0]] = parts[1]
		return acc
	}, {})
	useEffect(() => {
		const loadCallbackParams = async () => {
			if (parsedHash.id_token && parsedHash.access_token) {
				props.callbackLoginUser({
					accessToken: parsedHash.access_token,
					idToken: parsedHash.id_token,
					expiresIn: parsedHash.expires_in,
				})
			}
		}
		loadCallbackParams()
	}, [parsedHash])
	return (
		<Root className={classes.container}>
			<h1>Logging you in...</h1>
		</Root>
	)
}

Callback.propTypes = {
	callbackLoginUser: PropTypes.func.isRequired,
}

const mapStateToProps = ({ auth }) => {
	return {
		auth,
	}
}

export default compose(connect(mapStateToProps, actions), withTranslation())(Callback)
